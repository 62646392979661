import React from "react"
import Layout from "../components/Layout"
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"

export default () => {
  return (
    <Layout>
      <Helmet>
      <title>VM Construction - Thank you for contating us</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex flex-col justify-center items-center bg-gray-dark h-screen">
        <div className="text-4xl text-yellow font-bold font-rubik">Email sent!</div>
        <div className="text-xl text-gray-light">Thank you for your enquiry. We will be in touch shortly.</div>
        <Link className="text-gray-light underline uppercase font-rubik font-bold" to="/">Go home</Link>
      </div>
    </Layout>
  )
}
